// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/vastgoedverhuurmakelaardij/vastgoed-verhuur.png";
import logoPararius from "assets/images/vastgoedverhuurmakelaardij/pararius-logo.svg";
import logoFunda from "assets/images/vastgoedverhuurmakelaardij/funda-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Vastgoed Verhuurmakelaardij",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/VastgoedVerhuurmakelaardij",
      color: "facebook",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/vastgoedverhuurmakelaardij",
      color: "instagram",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/vastgoed-verhuurmakelaardij",
      color: "linkedin",
    },
  ],
  menus: [
    {
      name: "bedrijf",
      items: [
        { name: "Home", href: "/" },
        { name: "Aanbod", href: "/aanbod" },
        { name: "Verhuur / Beheer", href: "/woning-verhuren" },
        { name: "Huurders", href: "/huurders" },
        { name: "Wie zijn wij?", href: "/over-ons" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "Neem contact op", href: "/contact" },
        { name: "Vacatures", href: "/vacatures" },
        { name: "Contact verhuren", href: "/contact-verhuren" },
        { name: "FAQ verhuurders", href: "/veel-gestelde-vragen-verhuurders" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "Algemene voorwaarden", href: "/terms-and-conditions" },
        { name: "Privacyverklaring", href: "/privacy" },
      ],
    },
    {
      name: "aangesloten partners",
      items: [
        {
          name: "Pararius",
          href: "https://www.pararius.com/real-estate-agents/amstelveen/vastgoed-verhuurmakelaardij",
          logo: logoPararius,
        },
        {
          name: "Funda",
          href: "https://www.funda.nl/makelaars/amstelveen/81810-vastgoed-verhuurmakelaardij/woningaanbod/koop/heel-nederland/",
          logo: logoFunda,
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://svwdeveloper.nl/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        SvW Developer
      </MKTypography>
      .
    </MKTypography>
  ),
};
