import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchWoningenAsync = createAsyncThunk("woningen/fetchWoningen", async () => {
  try {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/wonen", {})
      .then(function (response) {
        return response.data.resultaten;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  } catch (e) {
    return [];
  }
});

export const woningenSlicer = createSlice({
  name: "woningen",
  initialState: {
    status: "loading",
    woningen: [],
  },
  reducers: {
    getWebsites: (state, action) => {
      // API.post("ClickAndLaunch", "/website", {
      //   body: {
      //     domain: action.payload.domain,
      //     domainName: action.payload.domainName.replace(".clickandlaunch.me", ""),
      //     websiteType: action.payload.websiteType,
      //     websiteTypeVersion: action.payload.websiteTypeVersion,
      //     subscriptionLevel: action.payload.subscriptionLevel,
      //   },
      // })
      //   .then((response) => {
      //     cogoToast.success("This will take a couple of minutes", {
      //       hideAfter: 5,
      //       position: "top-center",
      //       heading: "Launching your website!",
      //     });
      //     // state.creatingWebsiteStatus = 'done'
      //   })
      //   .catch((error) => {
      //     cogoToast.error(`Launching your website went wrong: ${error}`, {
      //       hideAfter: 5,
      //       position: "top-center",
      //       heading: "Error",
      //     });
      //   });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWoningenAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchWoningenAsync.fulfilled, (state, action) => {
        // Filter houses younger than 15 days and NOT 'VERHUURD'
        const recentAndAvailableWoningen = action.payload.filter((woning) => {
          const transactieDatum = new Date(woning.marketing.publicatiedatum);
          const daysSinceTransactie = (new Date() - transactieDatum) / (1000 * 60 * 60 * 24);
          return daysSinceTransactie <= 15 && woning.financieel.overdracht.status !== "VERHUURD";
        });

        // Filter and sort the remaining houses
        const otherWoningen = action.payload.filter(
          (woning) => !recentAndAvailableWoningen.includes(woning)
        );
        otherWoningen.sort(
          (a, b) =>
            Number(a.financieel.overdracht.huurprijs) - Number(b.financieel.overdracht.huurprijs)
        );

        // Concatenate the two arrays
        state.woningen = [...recentAndAvailableWoningen, ...otherWoningen];
        console.log(state.woningen);
        state.status = "idle";
      })
      .addCase(fetchWoningenAsync.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const { createWebsiteCall } = woningenSlicer.actions;

export default woningenSlicer.reducer;
